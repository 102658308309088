import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';
function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted');
    console.log(name);
    // Aquí es donde puedes enviar los datos del formulario al servidor o hacer cualquier otra acción necesaria
  };

  return (
    <div className='container contactFromWrapper'>
        <div className="row">
            <div className="col-md-12">
                <h2>Contáctanos</h2>
            </div>
            <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label>Correo</label>
                        <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Nombre</label>
                        <input type="text" className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='form-group'>
                        <label>Mensaje</label>
                        <textarea className='form-control' value={message} onChange={(e) => setMessage(e.target.value)} rows="5" />
                    </div>
                    <div className='form-group'>
                        <p>&nbsp;</p>
                        <button type="submit" className='form-control'>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
  );
}

export default Contact;