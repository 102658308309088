import React from 'react';

// Dependencias
import './Footer.css';


function Footer() {
    return (
      <footer>
          <p>&copy; 2023 Alberto Brime Producciones</p>
      </footer>
    );
}
  
export default Footer;