import React from 'react';
import video from './video/video.mp4';
import pareja from './img/pareja.jpg';
import dummy1 from './img/1.jpg';
import dummy2 from './img/2.jpg';
import dummy3 from './img/3.jpg';
import dummy4 from './img/4.jpg';
import Contact from '../contact/Contact';
// Dependencias
import './Main.css';

function Main() {
    return (
        <main>
            <div className="videoWrapper" id='inicio'>
                <div className="video-background">
                    <video autoPlay loop muted>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
                <div className="overlayVideo">
                    <h1> Alberto Brime Producciones</h1>
                </div>
            </div>
            <div className="quienes-somos-wrapper modulo" id='nosotros'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>¿Quienes Somos?</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-5 offset-md-1">
                            <img src={pareja} alt="" />
                        </div>
                        <div className="col-md-5">
                            <p>Somos una empresa especializada en bodas, que se enorgullece de proporcionar a nuestros clientes una experiencia de música excepcional. Nuestros DJ's tienen años de experiencia en la industria de la música y están dedicados a crear una atmósfera única y emocionante en cada evento.</p>
                            <p>Entendemos lo importante que es la música en su gran día, y trabajamos en estrecha colaboración con usted para garantizar que la selección musical sea perfecta para su ceremonia, recepción y fiesta. Ya sea que prefiera clásicos atemporales o éxitos actuales, nuestro catálogo musical cuenta con una amplia variedad de opciones para todos los gustos.</p>
                            <p>Además de proporcionar servicios de DJ, también ofrecemos servicios de iluminación y sonido para crear una experiencia audiovisual completa. Nuestros expertos técnicos de iluminación pueden transformar cualquier lugar de celebración en un espacio mágico y personalizado para su boda.</p>
                            <p>No dude en ponerse en contacto con nosotros para discutir cómo podemos hacer de su boda una experiencia musical única e inolvidable.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="galeria modulo" id="galeria">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Galería</h2>
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy1} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy2} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy3} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy4} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy1} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy2} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy3} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy2} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy3} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy2} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy3} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy2} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy3} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy3} alt="" />
                        </div>
                        <div className="col-md-4 mt-4">
                            <img src={dummy2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="contacto modulo" id="contacto">
                <Contact></Contact>
            </div>
        </main>
    );
}
  
export default Main;